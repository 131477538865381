import { useEffect } from "react";

const TallyForm = ({ formId, handleFormSubmission }) => {
  // The code below will load the embed
  useEffect(() => {
    if (formId) {
      const widgetScriptSrc = "https://tally.so/widgets/embed.js";

      const load = () => {
        // Load Tally embeds
        if (typeof window.Tally !== "undefined") {
          window.Tally.loadEmbeds();
          window.addEventListener("message", handleFormSubmission);
          return;
        }

        // Fallback if window.Tally is not available
        document.querySelectorAll("iframe[data-tally-src]:not([src])").forEach((iframeEl) => {
          iframeEl.src = iframeEl.dataset.tallySrc;
        });
      };

      // If Tally is already loaded, load the embeds
      if (typeof window.Tally !== "undefined") {
        load();
        return;
      }

      // If the Tally widget script is not loaded yet, load it
      if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
        const script = document.createElement("script");
        script.src = widgetScriptSrc;
        script.onload = load;
        script.onerror = load;
        document.body.appendChild(script);
        return;
      }
    }
  }, [formId]);

  return (
    <div>
      <iframe
        data-tally-src={`https://tally.so/embed/${formId}?alignLeft=1&transparentBackground=1&dynamicHeight=1`}
        loading="lazy"
        width="100%"
        height="369"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="Feedback form"
      ></iframe>
    </div>
  );
};

export default TallyForm;
