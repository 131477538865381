import * as React from "react";
import HeaderImage from "../../assets/img/smarteo_logo.png";
import styled from "styled-components";

function SvgComponent(props) {
  return (
    <Img className="radius8" src={HeaderImage} alt="office" style={{zIndex: 9,maxWidth:"200px"}} />
  );
}

export default SvgComponent;
const Img = styled.img`
  @media (max-width: 200px) {
    width: 80%;
    height: auto;
  }
`;